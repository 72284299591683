<template>
  <view-item title="客户充值">
    <template #operation>
      <en-button v-if="tabs.active === 'topup'" type="primary" @click="operation.add.click">新增充值单</en-button>
      <en-dropdown v-if="tabs.active === 'chargeable'" @command="operation.options.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item v-if="tabs.active === 'chargeable'" command="sms">发送短信</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'chargeable'" command="sms-batch">全部发送短信</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'chargeable'" command="download">导出</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="充值" name="chargeable">
              <table-dynamic
                code="CSMCHGABL"
                :data="chargeable.data"
                :loading="chargeable.loading"
                :height="height - 55"
                pagination
                :paging="chargeable.paging"
                :method="chargeable.get"
                :config="chargeable.config"
                show-selectable
                @selection-change="chargeable.selection.change"
              >
                <template #OPERATION="{ row }: { row: CustomerChargeableDto }">
                  <en-button type="primary" link @click="chargeable.operation.topup.click(row)">充值</en-button>
                  <en-button type="primary" link @click="chargeable.operation.refund.click(row)">退款</en-button>
                  <en-button type="primary" link @click="chargeable.operation.transfer.click(row)">调用余额</en-button>
                  <en-button type="primary" link @click="chargeable.operation.history.click(row)">账户流水</en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>

            <en-tab-pane label="充值记录" name="topup">
              <table-dynamic
                code="TOPUPFD"
                :data="topup.data"
                :loading="topup.loading"
                :height="height - 55"
                pagination
                :paging="topup.paging"
                :config="topup.config"
                :method="topup.get"
              >
                <template #OPERATION="{ row }: { row: any }">
                  <en-button type="primary" link @click="topup.operation.print.click(row)">打印</en-button>
                  <button-delete :method="topup.operation.delete.click" :params="row">删除</button-delete>
                </template>
              </table-dynamic>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="detail.visible" :title="`新建${detail.form.data.type?.message}单`">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :ref="setRef('detailForm')" :loading="detail.form.loading">
      <en-form-item label="业务类型">
        <select-maintain
          v-model="detail.form.data.type"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['TOPUPTYPE']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          disabled
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="客户编号">
        <en-input :model-value="detail.form.data.customer?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="客户名称" prop="customer.id">
        <select-maintain
          v-model="detail.form.data.customer"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => {
              params.payload = {
                quickSearch: value,
                rsexp: 'id,serialNo,name,cellphone,membershipCardNo,ownedVehicles[plateNo],accountInfo[chargeable]'
              }
            }
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        >
          <template #default="{ option }: { option: EnocloudCommonDefinitions['CustomerDto'] }">
            <div class="flex items-center justify-between">
              <span>{{ option.name }}</span>
              <span class="text-xs text-gray-200">{{ option.cellphone }}</span>
            </div>
          </template>
        </select-maintain>
      </en-form-item>
      <en-form-item label="手机号">
        <en-input :model-value="detail.form.data.customer?.cellphone" disabled></en-input>
      </en-form-item>
      <en-form-item label="拥有车辆">
        <en-input :model-value="detail.form.data.customer?.ownedVehicles.map((item) => item.plateNo).join(',')" disabled></en-input>
      </en-form-item>
      <en-form-item label="会员卡号">
        <en-input :model-value="detail.form.data.customer?.membershipCardNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="当前可用余额">
        <en-input :model-value="detail.form.data.customer?.accountInfo?.chargeable" disabled></en-input>
      </en-form-item>
      <en-form-item :label="detail.form.data.type?.code === 'TOP' ? '本次充值金额' : '本次扣除金额'" prop="chargeableAmount">
        <en-input-number
          v-model="detail.form.data.chargeableAmount"
          :min="0"
          :max="detail.form.data.type?.code === 'TOPR' ? detail.form.data.customer?.accountInfo?.chargeable : Infinity"
          :precision="2"
          class="w-full"
        ></en-input-number>
      </en-form-item>
      <en-form-item :label="detail.form.data.type?.code === 'TOP' ? '实际收款金额' : '实际退款金额'" prop="paymentAmount">
        <en-input-number v-model="detail.form.data.paymentAmount" :min="0" :max="10000000" :precision="2" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="销售人员">
        <select-maintain
          v-model="detail.form.data.salesman"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        >
        </select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="detail.form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax type="primary" :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-drawer v-model="transfer.visible" title="调用他人余额">
    <en-form :model="transfer.form.data" :rules="transfer.form.rules" :ref="setRef('transferForm')" :loading="transfer.form.loading">
      <en-form-item label="选择客户" prop="fromCustomer.id">
        <select-maintain
          v-model="transfer.form.data.fromCustomer"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => {
              params.payload = {
                quickSearch: value,
                positiveChargeable: true,
                excludedId: ''
              }
            }
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
          @change="transfer.form.fromCustomer.change"
        >
          <template #default="{ option }: { option: EnocloudCommonDefinitions['CustomerDto'] }">
            <div class="flex items-center justify-between">
              <span>{{ option.name }}</span>
              <span class="text-xs text-gray-200">{{ option.cellphone }}</span>
            </div>
          </template>
        </select-maintain>
      </en-form-item>
      <en-form-item label="客户余额">{{ formatMoney(transfer.form.data.remainingAmount) }}</en-form-item>
      <en-form-item label="金额" prop="amount">
        <en-input-number v-model="transfer.form.data.amount" :min="0" :max="transfer.form.data.remainingAmount" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="transfer.form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="transfer.footer.cancel.click">取消</en-button>
      <button-ajax type="primary" :method="transfer.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-drawer v-model="history.visible" title="账户流水" direction="btt" size="80%">
    <flex-box>
      <template #default="{ height }">
        <en-table
          :data="history.table.data"
          :height="height"
          :method="history.table.get"
          :paging="history.table.paging"
          pagination
          :loading="history.table.loading"
        >
          <en-table-column label="客户名称" prop="customer.name"></en-table-column>
          <en-table-column label="单据类型" prop="accountTransactionType.message"></en-table-column>
          <en-table-column label="单据编号" prop="documentSerialNo"></en-table-column>
          <en-table-column label="流水金额" prop="amount">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['AccountHistoryDto'] }">{{ formatMoney(row.amount) }}</template>
          </en-table-column>
          <en-table-column label="流水日期" prop="datetime">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['AccountHistoryDto'] }">{{ formatDate(row.datetime) }}</template>
          </en-table-column>
          <en-table-column label="流水备注" prop="comment"></en-table-column>
          <en-table-column label="操作人" prop="preparedBy.name"></en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="history.footer.cancel.click">取消</en-button>
    </template>
  </en-drawer>

  <short-message-template v-model="sms.visible" :data="sms.data"></short-message-template>
</template>

<script lang="ts">
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { ShortMessageTemplate } from '@enocloud/business'
import { print, Topup } from '@enocloud/print'

type CustomerChargeableDto = EnocloudCommonDefinitions['CustomerChargeableDto']

export default factory({
  components: {
    ShortMessageTemplate
  },

  config: {
    children: {
      operation: {
        children: {
          add: {
            click() {
              this.detail.form.init()
              this.detail.form.data.type = { code: 'TOP', message: '充值', description: '', type: '' }
              this.detail.visible = true
            }
          },
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/chargeable/export',
                params(params) {
                  params.payload = this.chargeable.$ajaxParams.payload
                }
              }
            }
          },
          options: {
            async command(option: string) {
              switch (option) {
                case 'sms':
                  if (!this.chargeable.selection.data.length) return EnMessage.warning('请选择客户')
                  this.sms.data = this.chargeable.selection.data
                  this.sms.visible = true
                  break
                case 'sms-batch':
                  this.sms.data = this.chargeable.data
                  this.sms.visible = true
                  break
                case 'download':
                  await this.operation.export.get()
                  this.store.openDownload()
              }
            }
          }
        }
      },
      tabs: {
        active: 'chargeable' as 'chargeable' | 'topup'
      },
      chargeable: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/chargeable/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          CUSTOMER_SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'name' } } },
          CUSTOMER_CELLPHONE: { header: { filter: { type: 'text', field: 'cellphone' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          MEMBERSHIP_CARD_NO: { header: { filter: { type: 'text', field: 'cardNo' } } },
          REMAINING_AMOUNT: {
            header: {
              filter: {
                type: 'select',
                field: 'positiveChargeable',
                props: {
                  options: [
                    { message: '非0余额', code: true },
                    { message: '0余额', code: false },
                    { message: '全部', code: '' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        },
        children: {
          selection: {
            data: [] as CustomerChargeableDto[],
            change(rows: CustomerChargeableDto[]) {
              this.chargeable.selection.data = rows
            }
          },
          operation: {
            topup: {
              async click(row: CustomerChargeableDto) {
                const res = await this.ajax('GET /enocloud/common/customer/:customerId', { paths: [row.id!] })
                if (res.data[0]) {
                  this.detail.form.init()
                  this.detail.form.data.customer = res.data[0]
                  this.detail.form.data.type = { code: 'TOP', message: '充值', description: '', type: '' }
                  this.detail.visible = true
                }
              }
            },
            refund: {
              async click(row: CustomerChargeableDto) {
                const res = await this.ajax('GET /enocloud/common/customer/:customerId', { paths: [row.id!] })
                if (res.data[0]) {
                  this.detail.form.init()
                  this.detail.form.data.customer = res.data[0]
                  this.detail.form.data.type = { code: 'TOPR', message: '退款', description: '', type: '' }
                  this.detail.visible = true
                }
              }
            },
            transfer: {
              click(row: CustomerChargeableDto) {
                this.transfer.form.init()
                this.transfer.form.data.toCustomer = { id: row.id } as EnocloudCommonDefinitions['CustomerDto']
                this.transfer.form.data.type = { code: 'CHAG', message: '', description: '', type: '' }
                this.transfer.visible = true
              }
            },
            history: {
              click(row: CustomerChargeableDto) {
                this.history.form.data.customerId = row.id!
                this.history.form.data.accountTypeCode = 'CHAG'
                this.history.table.get()
                this.history.visible = true
              }
            }
          }
        }
      },
      topup: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/topup/query',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'POST /enocloud/common/customer/topup/:topupId/discard'
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          VEHICLES: { header: { filter: { type: 'text', field: 'plateNo' } } },
          MEMBERSHIP_CARD_NO: { header: { filter: { type: 'text', field: 'cardNo' } } },
          CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'customerName' } } },
          CUSTOMER_CELLPHONE: { header: { filter: { type: 'text', field: 'cellphone' } } },
          PREPARED_DATETIME: {
            header: { filter: { type: 'date', field: ['startDate', 'endDate'], props: { type: 'daterange' } } }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'typeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params, value) => (params.paths = ['TOPUPTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SALESMAN: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanName',
                props: {
                  allowCreate: true,
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          }
        },
        children: {
          operation: {
            print: {
              click(row: EnocloudCommonDefinitions['TopupQueryDto']) {
                print(Topup, { props: { data: row } })
              }
            },
            delete: {
              async click(row: EnocloudCommonDefinitions['TopupQueryDto']) {
                await this.topup.delete({ paths: [row.id] })
                return await this.topup.get()
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            rules: {
              'customer.id': [{ required: true, message: '请选择客户' }],
              chargeableAmount: [{ required: true, message: '请填写本次充值金额' }],
              paymentAmount: [{ required: true, message: '请填写实际收款金额' }]
            },
            data: {
              chargeableAmount: 0,
              paymentAmount: 0
            } as EnocloudCommonDefinitions['TopupDto'],
            ajax: {
              submit: {
                action: 'POST /enocloud/common/customer/topup',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            children: {}
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                try {
                  await this.refs.detailForm.validate()
                  await EnMessageBox.confirm(
                    `实际${this.detail.form.data.type?.message}金额为${this.detail.form.data.paymentAmount}元，是否继续结算！`,
                    '提示',
                    {
                      type: 'warning'
                    }
                  )
                  await this.detail.form.submit()
                  this.chargeable.get()
                  this.topup.get()
                  this.detail.visible = false
                } catch (err) {}
              }
            }
          }
        }
      },
      transfer: {
        visible: false,
        children: {
          form: {
            data: {
              amount: 0,
              remainingAmount: 0
            } as EnocloudCommonDefinitions['AccountTransferDto'] & { remainingAmount: number },
            rules: {
              'fromCustomer.id': [{ required: true, message: '请选择客户' }],
              amount: [{ required: true, message: '请填写金额' }]
            },
            ajax: {
              submit: {
                action: 'POST /enocloud/common/customer/account/transfer',
                validate: true,
                loading: true,
                params(params) {
                  params.payload = this.transfer.form.data
                }
              }
            },
            children: {
              fromCustomer: {
                async change(value: EnocloudCommonDefinitions['CustomerDto']) {
                  const res = await this.ajax('GET /enocloud/common/customer/chargeable/query', { payload: { accurateName: value.name } })
                  this.transfer.form.data.remainingAmount = res.data[0]?.remainingAmount ?? 0
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.transfer.visible = false
              }
            },
            confirm: {
              async click() {
                await this.transfer.form.submit()
                this.chargeable.get()
                this.topup.get()
                this.transfer.visible = false
              }
            }
          }
        }
      },
      history: {
        visible: false,
        children: {
          form: {
            data: {
              customerId: '' as string | number,
              accountTypeCode: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/account',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.history.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.history.visible = false
              }
            }
          }
        }
      },
      sms: {
        visible: false,
        data: [] as CustomerChargeableDto[]
      }
    }
  },

  mounted() {
    this.chargeable.get()
    this.topup.get()
  }
})
</script>
